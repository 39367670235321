import React, { useState, useEffect } from 'react';
import WebApi from '../helpers/WebApi';
import { useSnackbar } from '../notification/index';
import Buttons from '../components/buttons/buttons';


function ListLinkDoctors({ }) {

    let web_api = new WebApi;
    let config = window.config;
    const { enqueueSnackbar } = useSnackbar();

    const [allDoctorsData, setAllDoctorsData] = useState([]);

    const [branches, setBranches] = useState([]);

    const [selectedBranch, setSelectedBranch] = useState(null);
    // Получения списка всех врачей
    useEffect(() => {
        web_api.getClinicBranches((res) => {

            if (res == 'error') {
                enqueueSnackbar({
                    message: 'Не удалось получить филиалы',
                    options: {
                        countdown: 5000,
                        dismissible: true,
                        variant: 'error'
                    }
                });
            } else {
                setBranches(res);
            };
        });




        web_api.getAllDoctors((res) => {

            if (res == 'error') {
                enqueueSnackbar({
                    message: 'Не удалось получить список врачей',
                    options: {
                        countdown: 5000,
                        dismissible: true,
                        variant: 'error'
                    }
                });
                return false
            } else {
                generateDoctorsLinks(res);                
            };
        });
    }, []);

    function generateDoctorsLinks(listAllDoctors, branch){
        // Генерируем ссылку на каждого врача и добавляем к массиву
        listAllDoctors.map((e, i) => {
            const doctorId = e.id;
            const currentProtocol = document.location.protocol;
            const currentHost = document.location.host;
            const doctorGet = '?doctor=' + doctorId;
            let folder;
            if (config.batch != '/') { folder = config.batch } else { folder = '' }
            let linkDoctor = currentProtocol + '//' + currentHost + '/' + doctorGet;
            if(branch){
                linkDoctor += `&branch=${branch.id}`
            }
            e.link_doctor = linkDoctor;
        })

        setAllDoctorsData([...listAllDoctors]);
    }

    function selectBranch(branchIndex){
        web_api.getDoctors({clinicBranchId: branches[branchIndex].id}, function (res) {

            if (res == 'error') {
              enqueueSnackbar({
                message: 'Не удалось получить врачей',
                options: {
                  countdown: 5000,
                  dismissible: true,
                  variant: 'error'
                }
              });
      
              return false;
      
            } else {
                generateDoctorsLinks(res, branches[branchIndex]);        
                setSelectedBranch(branches[branchIndex]);
            }      
          });
    }


    return (
        <div className='links-doctors'>
            <div className={"reception-branch"} style={{marginBottom: '20px'}} >
                {branches.length > 0 && <> {branches.map((branch, i) =>
                    <Buttons onClick={() => selectBranch(i)} className={selectedBranch?.id == branch.id ? 'active' : ''} key={i} id={branch.id} dataAdress={branch.addressLocation ? branch.addressLocation : "---"} dataName={branch.name} type="button-branch" text={branch.name} subtext={branch.addressLocation ? branch.addressLocation : "---"} icon="icon icon_s icon_background-auto icon_button-branch" />
                )}</>}
            </div>
            <h2>Список всех врачей компании "{config.name_company}"</h2>
            <div className='links-doctors__container'>
                {allDoctorsData.length ? <> {allDoctorsData.map((el, key) =>
                    <div className='links-doctors__item' key={key}> 
                        <div>
                            {el.fullName + " - "}
                        </div>
                        <a href={el.link_doctor}>{el.link_doctor}</a>
                    </div>
                )}</> : <>
                    <div>врачей нет</div>
                </>}
            </div>
        </div>
    )

}

export default ListLinkDoctors;