import React from 'react';
import './reception-block.css';
import moment from "moment";
import Done from '../img/done.png';
import Buttons from '../components/buttons/buttons';


function ReceptionSuccess({ active, data }) {

  let config = window.config;

  // Преобразование даты начала и окнчания приема под формат даты используемый Google calendar
  let start = moment(data.date);
  let getTimeBegin = moment(data.timeBegin, 'HH:mm');
  let getTimeEnd = moment(data.timeEnd, 'HH:mm');
  let end = moment(data.date);

  start = start.set({
    hour: getTimeBegin.get('hour'),
    minute: getTimeBegin.get('minute'),
    second: getTimeBegin.get('second')
  });

  end = end.set({
    hour: getTimeEnd.get('hour'),
    minute: getTimeEnd.get('minute'),
    second: getTimeEnd.get('second')
  });

  start = new Date(start);
  end = new Date(end);

  start = moment(start).format('YYYYMMDD[T]HHmm00[Z]Z');
  end = moment(end).format('YYYYMMDD[T]HHmm00[Z]Z');

  // Переменная с данными для отправки в google календарь
  let dataCalendar = {
    adress: '',
    service: '',
    doctor: '',
    typeService: '',
    nameClinic: "Филиал: " + data.name,
  }

  // Добавление услуги и типа услуги к dataCalendar
  if (config.selectService == true) {
    dataCalendar.service = "<br>Услуга: " + data.serviceText;
    dataCalendar.typeService = "<br>Тип услуги: " + data.typeServiceText;
  } else {
    dataCalendar.service = '';
    dataCalendar.typeService = '';
  }

  // Добавление адреса к dataCalendar
  if (data.adress != '' && data.adress != null && data.adress != undefined) { dataCalendar.adress = data.adress } else { dataCalendar.adress = '' };
  // Добавление врача к dataCalendar
  if (data.doctorText) { dataCalendar.doctor = "<br>Врач: " + data.doctorText } else { dataCalendar.doctor = '' }

  let details = dataCalendar.nameClinic + dataCalendar.service + dataCalendar.typeService + dataCalendar.doctor;

  function receptionRepeat() {
    window.location.reload();
  }


  return (
    <div className={"reception-success"}>
      <div className="reception-success__container">
        <div className="reception-success__title">
          <div className="reception-success__img">
            <img src={Done} />
          </div>
          <div className='reception-success__title-text'>Вы успешно записаны на прием</div>
        </div>
        <div className='reception-success__info-container'>
                  
          <div className="reception-success__info">
            <div className="reception-success__time">
              {data.dateText} на {data.timeBegin != false ? (data.timeBegin.slice(0, -3) + " - " + data.timeEnd.slice(0, -3)) : "33"}
              <span><span style={{ color: "#000" }}>к врачу </span>{data.doctorText}</span>
            </div>
            <div><span>Филиал: </span>{data.name}</div>
            {data.adress != '' && data.adress != null && data.adress != undefined &&
              <div><span>Адрес: </span>{data.adress}</div>
            }
            {config.selectService == true && data.serviceText && 
              <div><span>Услуга: </span>{data.serviceText}</div>
            }
            {(config.selectService == true) && (config.show_services_price == true) && data.price &&
              <div><span>Цена услуги: </span>{data.price}</div>
            }
            <div><span>Врач: </span>{data.doctorText}</div>
            {config.selectService == true && data.typeServiceText &&
              <div><span>Тип услуги: </span>{data.typeServiceText}</div>
            }
            {config['finish_text'] && 
              <div className='reception-success__info-text'>{config['finish_text']}</div>            
            }
            <div className='reception-success__info-button-container' >
              <a className="button button-primary button-google-calendar" style={{ marginRight: '12px', marginBottom: '12px', boxSizing:'border-box', height: '50px', width: '275px' }} href={"http://www.google.com/calendar/event?action=TEMPLATE&text=Запись на приём&dates=" + start + "/" + end + "&details=" + details + "&location=" + dataCalendar.adress + "&trp=false&sprop=&sprop=name:"}
                target="_blank" rel="nofollow">Добавить в Google календарь</a>
              <Buttons type="button" style={{ order: "5", marginBottom: '12px' }} text={"Записаться повторно"} onClick={receptionRepeat} />
            </div>
            
          </div>
         
          {config['map_link'] && data.isOneBranch
            ? <div className='reception-success__info-map'>
              <iframe src={config['map_link']}  width='100%' height='100%' frameBorder="0"></iframe>
            </div>
            : ''
          } 
        </div>
        

      </div>
    </div >
  )

}

export default ReceptionSuccess;