import React, { useState, useEffect } from 'react';
import WebApi from '../helpers/WebApi';
import Session from '../helpers/Session.js';
import { useSnackbar } from '../notification/index';
import { extendMoment } from "moment-range";
import HeaderMobile from '../components/HeaderMobile.jsx';
import ReceptionBranch from './ReceptionBranch.jsx';
import ReceptionServices from './ReceptionServices.jsx';
import TopLoader from "react-top-loader";
import originalMoment from "moment";
import 'moment/locale/ru';
import './reception-block.css';

const moment = extendMoment(originalMoment);

function ListLinkService() {

  let web_api = new WebApi;
  let config = window.config;

  const { enqueueSnackbar } = useSnackbar();

  // Стилизация загрузки страницы до ответа с сервера
  const [loader, setLoader] = useState(true);
  const [receptionLoader, setReceptionLoader] = useState(false);

  // Переменная для сбора данных для отправки на сервер
  const [data, setData] = useState({});

  // Переменная для отображения текущего этапа записи на приём
  const [currentTab, setCurrentTab] = useState('branch');

  // Переменная с типами услуг 
  const [typeService, setTypeService] = useState([]);

  // Активный тип услуги
  const [activeType, setActiveType] = useState(false);

  // Переменные для передачи данных в каждый этап
  const [branches, setBranches] = useState([]); // филиалы с сервера
  const [servicesReception, setServicesReception] = useState([]); // Услуги с сервера

  // id выбранного филиала для сраванения с типом услуги в Reception services
  const [idType, setIdType] = useState('');

  const [messageActive, setMessageActive] = useState(false);

  useEffect(() => {
  }, [data])

  // Переключение табов на мобильной версии

  // переменная с названиями блоков
  const allTabs = ['branch', 'selectType', 'services', 'doctor', 'success', 'finish'];

  if (branches.length == 1) {
    allTabs.splice(0, 1);
  }

  // Запрос для получения доступных типов услуг

  function getTypes() {

    web_api.getAppointmentTypes((res) => {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить типы услуг',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });
      } else {

        if (res.length == 1) {
          let params = data;
          params.typeServiceText = res[0].value;
        }
        
        setTypeService(res);
      }
    });
  }

  useEffect(() => {
    getTypes()
  }, []);

  // РАБОТА С ФИЛИАЛАМИ

  // Переменная, куда заносятся данные, если филиал всего 1. 
  // При изменении данной константы отрабатывает Use Effect с запросом к API

  const [oneBranch, setOneBranch] = useState(data)
  const adressLocation = window.location;
  const [branchId, setBranchId] = useState('');


  useEffect(() => {

    web_api.getClinicBranches((res) => {

        if (res == 'error') {
            enqueueSnackbar({
                message: 'Не удалось получить филиалы',
                options: {
                    countdown: 5000,
                    dismissible: true,
                    variant: 'error'
                }
            });
            return false
        } else {
            setLoader(false);


            // Убираем выбор филиала, если филиал 1
            if (res.length == 1) {
                
                setBranches(res);
                let params = data;

                params.clinicBranchId = res[0].id;
                params.serviceType = 0;
                params.name = res[0].name;
                params.adress = res[0].addressLocation;
                params.fromDate = start_period.start_date.format('YYYY-MM-DD');
                params.tillDate = start_period.end_date.format('YYYY-MM-DD');

                // Добавляем тип записи на услугу
                setIdType(0);

                // Добаляем в переменную данные единственного филиала
                setOneBranch(params);  // Дальше запрос в API производится в отдельном UseEffect
                
                setBranchId(params.clinicBranchId)
                
                // Переходим на выбор услуги
                setData(prevState => ({
                  ...prevState,
                  'isOneBranch': true
                }));
                setTypeReception('select-service');
                setCurrentTab('services');
                GetServices(params);
                
            } else {
                setBranches(res);
            }
        };
    });
  }, [adressLocation])

  // Функция выбора филиала
  function SelectBranch(event) {

    let params = data;
    let branch_id;

    // Добавляем id филиала
    setIdType(event.target.id);

    // Запрос на получение докторов по id филиала, если выбор услуги отключен
    if (!event.currentTarget.id) branch_id = event.target.id; else branch_id = event.currentTarget.id;
    params.clinicBranchId = branch_id;
    params.serviceType = typeService[0].key;
    params.name = event.currentTarget.dataset.name;
    params.adress = event.currentTarget.dataset.adress;
    params.typeServiceText = typeService[0].value;
    params.fromDate = start_period.start_date.format('YYYY-MM-DD');
    params.tillDate = start_period.end_date.format('YYYY-MM-DD');

    // Переходим на выбор услуги
    setTypeReception('select-doctor')
    setData(params);
    setBranchId(params.clinicBranchId);
    GetServices(params);

    let session = new Session;

    if (session.getSession('MessageSwipe')) {
      setMessageActive(false);
    }
    else {
      setMessageActive(true);
      session.createSession('MessageSwipe', true, 1000 * 60 * 60 * 24 * 30);
    }

  }

  function GetServices(params, typeService = 'Платный приём') {

    // Запрос на получение услуги по id филиалу и типу услуги 
    web_api.getOnlineServices(params, function (res) {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить услуги',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;

      } else {

        if (!params.typeServiceText) { params.typeServiceText = typeService; }

        setReceptionLoader(false);
        setData(params);
        setServicesReception(res);
        setActiveType(true);
        setCurrentTab('services');
      }
    });
  }

  const [typeReception, setTypeReception] = useState(false);

  // РАБОТА С УСЛУГАМИ

  // Функция выбора типа услуги
  function SelectType(event) {

    let params = data;
    params.serviceType = parseInt(event.target.dataset['id']);
    params.typeServiceText = event.target.innerText;
    // Добавляем id филиала
    setIdType(params.serviceType);
    // Запрос на получение услуги по id филиалу и типу услуги
    web_api.getOnlineServices(params, function (res) {

      if (res == 'error') {
        enqueueSnackbar({
          message: 'Не удалось получить услуги',
          options: {
            countdown: 5000,
            dismissible: true,
            variant: 'error'
          }
        });

        return false;

      } else {
        setReceptionLoader(false);
        setData(params);
        setServicesReception(res);
        setActiveType(true);
        setCurrentTab('services')
      }
    });
  }

  // Переменная с текущей неделей
  let [start_period, setStartPeriod] = useState({
    start_date: moment().startOf("isoWeek"),
    end_date: moment().endOf("isoWeek")
  });

  // Функция выбора услуги
  function SelectService(event) {

    let params = data;
    let serviceSelect;

    if(event.target.className == 'reception-services__block'){
      serviceSelect = (event.target.firstElementChild.innerText).replace(/[0-9]/g, '');
    } else {
      serviceSelect = (event.target.innerText).replace(/[0-9]/g, '');
    }

    if(config.show_services_price && event.target.lastChild.innerText || event.target.nextSibling.innerText){
      params.price = event.target.lastChild.innerText || event.target.nextSibling.innerText;
    }

    const serviceId = event.target.id || event.target.firstElementChild.id;
    const currentProtocol = document.location.protocol;
    const currentHost = document.location.host;
    let folder;
    if (config.batch != '/') { folder = config.batch } else { folder = '' }
    const linkService = currentProtocol + '//' + currentHost  + '/' + `?branch=` + data.clinicBranchId + '&service=' + serviceId;

    navigator.clipboard.writeText(linkService);
    
    enqueueSnackbar({
        message: 'Ссылка скопирована в буфер обмена',
        options: {
          countdown: 5000,
          dismissible: true,
          variant: 'info'
        }
      });
  }

  return (<>
    {loader &&
      <TopLoader show cowerlor="var(--primary)" thickness="4px" />
    }
    <div className="reception" style={loader ? { opacity: '0.75', filter: 'blur(3px)', pointerEvents: 'none' } : { opacity: '1' }}>
      <HeaderMobile title="Запись на прием" type="reception" />
      {/* <Header title="Запись на прием" type="reception" /> */}
        {!loader && <div style={receptionLoader ? { opacity: '0.75', filter: 'blur(3px)', pointerEvents: 'none', transition: 'all 0.5s ease' } : { opacity: '1', transition: 'all 0.5s ease' }}>
          {currentTab == 'branch' &&
            <><ReceptionBranch data={branches} onClick={event => { SelectBranch(event) }} /> </>
          }
          {currentTab == 'services' && config.selectService == true &&
            <ReceptionServices
              data={servicesReception}
              typeService={typeService}
              onClick={event => { SelectService(event) }}
              onClickType={event => SelectType(event)}
              activeType={activeType}
              idType={idType}
            />
          }
        </div>}
    </div>
  </>)

}

export default ListLinkService;